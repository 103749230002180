.container404{
    width: 100%;
    height: auto;
}

.error404{
    width: 270px;
    display: inline-block;
}

.image404{
    background-image: url('./image404.svg');
    background-size: contain;
    width: 110px;
    height: 110px;
    float: right;
    margin-top: -14px;
}
.shadow-pop-tr {
	-webkit-animation: shadow-pop-tr 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate both;
	        animation: shadow-pop-tr 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate both;
}

 @-webkit-keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent;
              box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px transparent, 2px -2px transparent, 3px -3px transparent, 4px -4px transparent, 5px -5px transparent, 6px -6px transparent, 7px -7px transparent, 8px -8px transparent;
              box-shadow: 1px -1px transparent, 2px -2px transparent, 3px -3px transparent, 4px -4px transparent, 5px -5px transparent, 6px -6px transparent, 7px -7px transparent, 8px -8px transparent;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  @keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent;
              box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px transparent, 2px -2px transparent, 3px -3px transparent, 4px -4px transparent, 5px -5px transparent, 6px -6px transparent, 7px -7px transparent, 8px -8px transparent;
              box-shadow: 1px -1px transparent, 2px -2px transparent, 3px -3px transparent, 4px -4px transparent, 5px -5px transparent, 6px -6px transparent, 7px -7px transparent, 8px -8px transparent;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  