.ticket-button-list{
	display: none;
	background-color: #EEE;
	width: 400px;
	border-radius: 10px;
	font-family: 'MadeTommy-SoftBold';
	height:44px;
}

@media only screen and (max-width: 876px) {
    /* For mobile devices */
    .ticket-button-list{
        display: block;
    }
}

.ticket-button-list > .btn-select{
	float: left;
	width: 356px;
	height: 44px;
	padding: 10px 12px;
}

.ticket-button-list > .btn-select-arrow{
	float: right;
	width:44px;
	height: 44px;
	background-image: url('./btn-select-arrow.svg');
	background-position: center;
	background-size: 51%;
	background-repeat: no-repeat;
	transition: 0.5s;
}
.ticket-button-list > .btn-select-arrow.rotated{
	transform: rotate(-180deg);
}
