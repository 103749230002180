.NewMessage{
    margin-top: 0 !important;
    padding: 4px 8px 8px 8px;
    border-radius: 0;
    transition: height .3s ease;
    display: none;
}

.NewMessage.open{
    display: block;
}