@font-face {
  font-family: 'MadeTommy-SoftBold';
  src: url('made-tommy-soft-medium.otf');
}
@font-face {
  font-family: 'MadeTommy-SoftLight';
  src: url('made-tommy-soft-light.otf');
}
@font-face {
  font-family: 'MadeTommy-Regular';
  src: url('made-tommy-regular.otf');
}
@font-face {
  font-family: 'MadeTommy-Bold';
  src: url('made-tommy-bold.otf');
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'MadeTommy-SoftLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.box{
    box-shadow: none;
}

.priority-mark{
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #FFF;
    width: auto;
    text-align: center;
  }

  .priority-mark.pending{
    background-color: #ffdd57;
    color: #212121;
  }
  .priority-mark.in-progress{
    background-color: #00d1b2;
    color: #212121;
  }
  .priority-mark.answered{
    background-color: #3273dc;
    color: #FFFFFF;
  }
  .priority-mark.waiting{
    background-color: #fffc57;
    color: #212121;
  }
  .priority-mark.solved{
    background-color: #48c774;
    color: #FFFFFF;
    font-weight: bold;
  }
  .priority-mark.finished{
    background-color: #48c774;
    color: #FFFFFF;
    font-weight: bold;
  }
  .priority-mark.closed{
    background-color: #f5f5f5;
    color: #909090;
  }

  body{background-color: #FFF;}

  .profile-button{
    background-color: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    display: grid;
    align-items: center;
    text-align: center;
    right: 12px;
    top: 6px;
    border-radius: 22px;
    background-color: #CDCDCD;
    transition: 0.2s;
  }
  .profile-button:hover{
    background-color: #e9e9e9;
    cursor: pointer;

  }
  .profile-button span{
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'MadeTommy-SoftBold';
    color: #212121;
  }

.profile-button img{
  width: 40px;
  min-width: 40px !important;
  height: 40px;
  min-height: 40px !important;
  background-color: #000;
  border-radius: 30px;
  padding: 0;
  border: 1px solid #FFFFFF;
}
.profile-box{
  position: absolute;
  width: 240px;
  top: 52px;
  right: 4px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 2px #c5c5c5;
}
.profile-box ul{
  list-style-type: none;
}
.profile-box ul li {
  font-size: 11px;
  padding: 8px;
  border-bottom: 1px solid #b1b1b1;
  transition: 200ms;
}
.profile-box ul li.hovereable:hover{
  background-color: #ececec;
  cursor: pointer;
}

.avatar{
  background-color: transparent;
  width: 44px;
  height: 44px;
  display: inline-grid;
  align-items: center;
  text-align: center;
  border: 2px solid #212121;
  border-radius: 40px;
  background-color: #CCC;
  color: #212121;
}

.avatar img{
  width: 40px;
  min-width: 40px !important;
  height: 40px;
  min-height: 40px !important;
  background-color: #000;
  border-radius: 30px;
  padding: 0;
  object-fit: cover;
}
.avatar span{
  font-size: 22px;
  text-transform: uppercase;
  font-family: 'MadeTommy-SoftBold';
  color: #212121;
}

.notification-button{
  background-color: transparent;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 60px;
  top: 4px;
  border-radius: 4px;
}
.notification-button:hover{
  background-color: #e9e9e9;
}

.notification-button img{
width: 32px;
min-width: 32px !important;
height: 32px;
min-height: 32px !important;
background-color: #000;
border-radius: 30px;
padding: 0;
margin: 6px;
}
.notification-button .counter{
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #FFFFFF;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  padding-top: 1px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.notification-button .counter.nine-plus{
  font-size: 12px;
  padding-top: 1px;
}
.notification-box{
  position: absolute;
  width: 340px;
  top: 52px;
  right: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px #c5c5c5;
}
.notification-box ul{
  list-style-type: none;
}
.notification-box ul li {
  font-size: 13px;
  padding: 8px;
  border-bottom: 1px solid #b1b1b1;
  transition: 200ms;
}
.notification-box ul li.hovereable:hover{
  background-color: #ececec;
  cursor: pointer;
}

.notification-icon{
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(4px);
}
.notification-icon.creation_incident{
  background-image: url('../public/icons/creation_incident.svg');
  background-size: contain;
}
.notification-icon.creation{
  background-image: url('../public/icons/creation.svg');
  background-size: contain;
}
.notification-icon.new_message{
  background-image: url('../public/icons/new_message.svg');
  background-size: contain;
}
.notification-icon.authorization{
  background-image: url('../public/icons/authorization.svg');
  background-size: contain;
}
.notification-icon.internal_note{
  background-image: url('../public/icons/note.svg');
  background-size: contain;
}
.notification-icon.assignation{
  background-image: url('../public/icons/assignation.svg');
  background-size: contain;
}
.notification-icon.manager_assignation{
  background-image: url('../public/icons/manager_assignation.svg');
  background-size: contain;
}
.notification-icon.mention{
  background-image: url('../public/icons/mention.svg');
  background-size: contain;
}
.notification-icon.authorized{
  background-image: url('../public/icons/authorized.svg');
  background-size: contain;
}
.notification-icon.unauthorized{
  background-image: url('../public/icons/unauthorized.svg');
  background-size: contain;
}
.attach_link{
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #7f8f9f;
  padding: 4px 28px 4px 8px;
  border-radius: 4px;
  background-image: url('./icons/download.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px);
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  color: #3273dc;  
}
.attach_link:hover{
  background-color: #7f8f9f;
  background-image: url('./icons/download-white.svg');
  color: #FFFFFF;
  cursor: pointer;
}

.avatar-mini{
  background-color: #CCC;
  width: 24px;
  height: 24px;
  display: inline-grid;
  margin-right: 8px;
  align-items: center;
  text-align: center;
  border-radius: 22px;
}
.avatar-mini span{
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'MadeTommy-SoftBold';
  color: #212121;
  font-weight: bold !important;
}

.avatar-mini img{
width: 24px;
min-width: 24px !important;
height: 24px;
min-height: 24px !important;
background-color: #000;
border-radius: 32px;
padding: 0;
}
.avatar-mini-enterprise span{
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'MadeTommy-SoftBold';
  color: #212121;
  font-weight: bold !important;
}

.upload_attach{
  width: 200px;
  height: 80px;
  background-color: #EFEFEF;
  border: 2px dashed #A1A1A1;
  text-align: center;
  color: #A1A1A1;
  padding-top: 16px;
  font-size: 12px;
  background-image: url('./icons/upload-grey.svg');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  background-position: center 36px;
}
.upload_attach:hover{
  background-image: url('./icons/upload-active.svg');
  color: #1C274C;
  border: 2px dashed #1C274C;
  cursor: pointer;
}

.attach_link_message{
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #7f8f9f;
  padding: 4px 28px 4px 8px;
  border-radius: 4px;
  background-image: url('./icons/download.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px);
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  color: #3273dc;
}
.attach_link_message:hover{
  background-color: #7f8f9f;
  background-image: url('./icons/download-white.svg');
  color: #FFFFFF;
  cursor: pointer;
}

.austero-menu-item{
  display: inline-block;
  margin-right: 10px;
  color: #3273dc;
  font-size: 14px;
}
.austero-menu-item:hover{
  cursor: pointer;
}
.close_new_message{
  height: 24px;
  float: right;
  font-size: 12px;
  font-family: "MadeTommy-SoftBold";
  color: #3273dc;
  text-decoration: underline;
}
.close_new_message:hover{
  cursor: pointer;
}

.attach_link_new{
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #7f8f9f;
  padding: 4px 28px 4px 8px;
  border-radius: 4px;
  background-image: url('./icons/delete.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px);
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  color: #3273dc;
}
.attach_link_new:hover{
  background-color: #7f8f9f;
  background-image: url('./icons/delete-white.svg');
  color: #FFFFFF;
  cursor: pointer;
}

.avatar-mini-enterprise{
  background-color: transparent;

  width: 24px;
  height: 24px;
  display: inline-grid;
  background-color: #CDCDCD;
  float: left;
  margin-right: 4px;
  /* transform: translateY(-3px); */
  align-items: center;
  text-align: center;
  border-radius: 40px;
}

.avatar-mini-enterprise img{
width: 24px;
min-width: 24px !important;
height: 24px;
min-height: 24px !important;
background-color: #000;
border-radius: 32px;
padding: 0;
object-fit: cover;
}

.avatar-mini-enterprise span{
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'MadeTommy-SoftBold';
  color: #212121;
}

.avatar-enterprise-big{
  background-color: transparent;
  width: 44px;
  height: 44px;
  background-color: #CDCDCD;
  border-radius: 40px;
  display: inline-grid;
  align-items: center;
  text-align: center;
  margin: 16px 8px 16px 12px;
  padding: 0;
}

.avatar-enterprise-big img{
width: 44px;
height: 44px;
background-color: #000;
object-fit: cover;
transform: translateY(12px);
border-radius: 30px;
}

.avatar-enterprise-big span{
  font-size: 22px;
  text-transform: uppercase;
  font-family: 'MadeTommy-SoftBold';
  color: #212121;
}

.enterprise-selection-btn{
  border: 2px solid #ccc;
  font-size: 15px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 16px;
  transition: 0.3s
}
.enterprise-selection-btn:hover{
  cursor: pointer;
  border-color: #2947aa;
  color: #2947aa;
  background-color: #f4f7ff;
}

.category-selection-btn{
  border: 2px solid #ccc;
  display: grid;
  align-items: center;
  margin: 0 0 8px 0;
  padding: 16px;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}
.category-selection-btn:hover{
  cursor: pointer;
  border-color: #2947aa;
  color: #2947aa;
  background-color: #f4f7ff;
}

.has-background-transparent{
  background-color: transparent !important;
}

.priority-icon{
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: 100%;
}
.priority-icon.low{
  background-image: url('icons/priority_low.svg');
}
.priority-icon.mid{
  background-image: url('icons/priority_mid.svg');
}
.priority-icon.high{
  background-image: url('icons/priority_high.svg');
}
.priority-icon.first_answer_ok{
  background-image: url('icons/first_answer_ok.svg');
}
.priority-icon.first_answer_waiting{
  background-image: url('icons/first_answer_waiting.svg');
  display: inline-block;  
  transform: translateY(3px);
  width: 16px;
  height: 16px;
}

.btn-tickets:hover{
  color: #527ddb;
  cursor: pointer;
}

.login-photo{
  width: calc(100% - 8px);
  height: calc(100% - 16px);
  margin: 8px 0 8px 8px;
  background-image: url('photo/login-photo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px 0 0 16px;
}

.login-logo{
  position: absolute;
  background-image: url('photo/logo-login.png');
  width: 128px;
  height: 128px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  left: 50%;
  top: 8%;
  transform: translate(-50%, 0);
}

.login-links{
  position: absolute;
  z-index: 10;
  width: auto;
  height: 32px;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.login-links a div{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin: 0 8px 0 8px;
}

.login-links a div.www{
  background-image: url('./icons/www.svg');
  background-size: 95%;
}
.login-links a div.app{
  background-image: url('./icons/app.svg');
}
.login-links a div.instagram{
  background-image: url('./icons/instagram.svg');
  background-size: 97%;
}

.login-page-left{
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #F1F2F6;
  left: 0;
  top: 0;
}
.login-page-right{
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #F1F2F6;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 802px) {
  /* For mobile devices */
  .login-page-right{
    display: none;
  }
  .login-page-left{
    width: 100%;
  }
}

@media only screen and (max-height: 582px) {
  .login-text{
    display: none;
  }
}

.expiration-timer{
  background-image: url('icons/expiration_white.svg');
  background-size: 20px 20px;
  background-position: 2px 2px;
  background-repeat: no-repeat;
  min-width: 80px;
  height: 24px;
  background-color: #212121;
  color: #FFFFFF;
  border-radius: 24px;
  font-family: 'MadeTommy-SoftBold';
  display: inline-grid;
  align-items: center;
  padding: 2px 8px 2px 24px;
  font-size: 12px;
  transform: scale(75%);
}
.expiration-timer.almost_expired{
  background-color: #FF3300;
}
.expiration-timer.expired{
  background-color: #ff0000;
}

.expiration-answer-icon{
 width: 16px;
 height: 16px;
 background-size: contain;
 background-repeat: no-repeat;
 transform: translateY(4px);
}
.expiration-answer-icon.intime{
  background-image: url('icons/check_green.png');
}
.expiration-answer-icon.not-intime{
  background-image: url('icons/cross_red.png');
}
.expiration-answer-icon.locked{
  background-image: url('icons/locked.svg');
}

option{
  color: #212121 !important;
}