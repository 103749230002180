.bgMessage{
    background-color: #ffffff;
    padding: 8px !important;
}

.bgInternal{
    background-color: #fffbeb;
    padding: 8px !important;
}

.bgAuthorization{
    background-color: #effaf3;
    padding: 8px !important;
}